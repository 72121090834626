import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../components/layout";
// Icons
import { IoDiamond, IoLogoEuro, IoHammer } from 'react-icons/io5';
import Seo from "../components/seo";
import { StaticImage } from "gatsby-plugin-image";


const About = () => {

    return (
        <Layout>
                <Seo
      title="Sobre Nosotros - BIKESAFE"
      description="Diseñamos muchos de los productos que vendemos y ofrecemos un servicio de instalación completo para todos los productos."
    />
            <Container>
                <Row className="mt-5 mb-5 text-center">
                    <Col sm="12">
                        <h1>Sobre Nosotros</h1>
                        <h3>¿Quiénes somos? Somos una "tienda integral" para aquellos que quieren comprar productos para estacionamiento de bicicletas.</h3>
                        <StaticImage placeholder="blurred"  src="../images/about-header.jpg" className="mt-4 mb-4 shadow fluid rounded" alt="Bikesafe Fabrication" />
                    </Col>
                </Row>
                <Row>
                    <Col lg="6" sm="12">
                        <h2 className="float-lg-left subheading-product">Nuestros Objetivos:</h2>
                    </Col>
                    <Col lg="6" sm="12">
                        <p className="flow-text">
                        Diseñamos muchos de los productos que vendemos y ofrecemos un servicio de instalación completo para todos los productos.                        </p>
                        <ul>
                            <li className="border p-2 shade-on-hover">Ofrecemos un servicio completo con nuestra gama de productos, desde los prácticos portabicicletas Sheffield a los techos para bicicletas, soluciones de estacionamiento de bicicletas en casa y el Bike Dock.</li>
                            <li className="border p-2 shade-on-hover">¿Quiénes somos? Somos uno de los proveedores oficiales de TFL y suministramos nuestros productos a todo tipo de público, desde empresas, centros educativos, contratistas y autoridades locales a usuarios domésticos.</li>
                            <li className="border p-2 shade-on-hover">Ofrecemos estantes para bicicletas de gran calidad, incluida la instalación en casa, en la oficina o en espacios públicos.</li>
                            <li className="border p-2 shade-on-hover">Aspiramos a fabricar productos que se creen con las antigua tradición de basarse en la calidad, pero con un diseño innovador y con la atención al cliente en mente.</li>
                            <li className="border p-2 shade-on-hover">¿Quiénes somos? Somos la primera empresa que fabrica productos para estacionamiento de bicicletas a partir de acero reciclado: lo puede consultar en nuestra sección de reciclado.</li>
                        </ul>
                    </Col>
                </Row>
                <Row className="mt-5 mb-5 text-center">
                    <Col sm="12">
                        <h3>Si quiere saber más sobre nosotros, mire a continuación cómo se fabrican nuestros portabicicletas aquí, en nuestra fábrica del Reino Unido:</h3>
                    </Col>
                </Row>
                <Row className="text-center">
                    <Col lg="4" sm="12">
                        <StaticImage placeholder="blurred"  src= "../images/factory/1.jpg" alt="Bikesafe Fabrication" className="mt-3 fluid rounded" />
                    </Col>
                    <Col lg="4" sm="12">
                        <StaticImage placeholder="blurred"  src= "../images/factory/2.jpg" alt="Bikesafe Fabrication" className="mt-3 fluid rounded" />
                    </Col>
                    <Col lg="4" sm="12">
                        <StaticImage placeholder="blurred"  src="../images/factory/3.jpg" alt="Bikesafe Fabrication" className="mt-3 fluid rounded" />
                    </Col>
                    <Col id="offsetColumnOne" className="d-none d-lg-block" lg="2" />
                    <Col lg="4" sm="12">
                        <StaticImage placeholder="blurred"  src="../images/factory/4.jpg" alt="Bikesafe Fabrication" className="mt-3 fluid rounded" />
                    </Col>
                    <Col lg="4" sm="12">
                        <StaticImage placeholder="blurred"  src="../images/factory/5.jpg" alt="Bikesafe Fabrication" className="mt-3 fluid rounded" />
                    </Col>
                    <Col id="offsetColumnTwo" className="d-none d-lg-block" lg="2" />
                </Row>
                <Row className="mt-5 mb-5 text-center">
                    <Col sm="12">
                        <h3>
                        Todos nuestros productos tienen varias características en común que, en nuestra opinión, son esenciales a la hora de adquirir cualquier producto de mobiliario urbano:
                        </h3>
                        <p className="flow-text mt-5"><IoDiamond/> Gran calidad</p>
                        <p className="flow-text mt-5"><IoHammer/> Mantenimiento mínimo o inexistente</p>
                        <p className="flow-text mt-5"><IoLogoEuro/> Excelente relación calidad-precio</p>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )

}

export default About;